@import '~antd/dist/antd.less';
@import './theme/index.less';

body {
  font-family: PingFang SC !important;
}

.ant-menu-overflow {
  justify-content: right;
}

.navlink-active > .ant-menu-item::after {
  height: 0.25rem;
  background: #0088fe;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  background: #0088fe;
}

.anticon-down {
  color: black !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background: #0088fe;
  color: white;
}

.ant-btn-primary {
  background-color: #0189fe;
}

.ant-anchor-link {
  padding: 0 !important;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: #0088fe;
}

.menu-h5-drawer .ant-drawer-body {
  padding: 0 1rem 1rem;
}

.market_buy_modal {
  &.send {
    .title {
      font-size: 0.875rem;
      font-weight: 400;
      color: #333333;
    }

    .expressInfo {
      margin-top: 0.625rem;

      TextArea {
        resize: none;
      }
    }

    .sendBtn {
      width: 10.25rem;
      background: #009393;
      border-radius: 1.25rem;
      margin: 2rem auto 0 auto;
      text-align: center;
      line-height: 2.25rem;
      font-size: 0.875rem;
      font-weight: 400;
      color: #ffffff;
    }
  }

  .ant-modal-title {
    text-align: center;
    font-size: 1rem;
    font-weight: bold;
    color: #333333;
  }

  .ant-modal-header,
  .ant-modal-footer {
    border: none;
  }

  .ant-modal-body {
    padding: 0 1rem;

    .item {
      font-size: 0.875rem;
      color: #333333;

      .addressTile {
        color: #333333;
        font-size: 0.875rem;
        position: relative;

        .addressList {
          position: absolute;
          top: 1.6875rem;
          z-index: 9999999;
          width: 11.1875rem;
          max-height: 12.125rem;
          background: #ffffff;
          box-shadow: 0px 0.125rem 0.75rem 0px rgba(0, 0, 0, 0.1);
          border-radius: 0.625rem;
          overflow-y: scroll;

          .addressList_item {
            padding: 0.625rem;
            color: #333333;
            font-size: 0.875rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &.active {
              color: #009393;
            }
          }

          .nodata {
            padding: 1rem;
            text-align: center;
            color: #999999;

            span {
              color: #009393;
            }
          }
        }
      }

      .addressInfo TextArea {
        resize: none;
        margin-top: 0.625rem;
      }

      .ant-select-focused .ant-select-selector,
      .ant-select-selector {
        border: none;
        padding: 0;
        box-shadow: none;
      }

      .ant-select-selection-placeholder {
        color: #333333;
        font-size: 0.875rem;
      }

      .ant-select-arrow {
        right: 0.3125rem;
      }

      &.item_info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 1rem;
        .amount {
          flex: 1;
          text-align: right;
        }
      }

      .address {
        background: #ffffff;
        box-shadow: 0rem 0rem 0.75rem 0rem rgba(0, 0, 0, 0.1);
        border-radius: 0.25rem;
        margin-top: 1rem;
        padding: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 0.75rem;
        color: #333333;

        .phone {
          color: #999999;
          margin-top: 0.5rem;
        }
      }

      .price {
        font-size: 0.875rem;
        color: #f14d4d;

        span {
          color: #999999;
        }
      }

      .amount {
        font-size: 1.25rem;
        color: #f14d4d;
        font-weight: bold;

        span {
          font-size: 0.875rem;
          color: #999999;
          font-weight: 400;
        }
      }

      .ant-input-number {
        margin: 0 0.4375rem;
        width: 2.5rem;
        height: 1.375rem;

        .ant-input-number-input {
          height: 1.375rem;
          padding: 0.1875rem 0.3125rem;
          text-align: center;
        }
      }
    }

    .operation {
      margin-top: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      line-height: 2.25rem;
      font-size: 0.875rem;
      font-weight: 400;
      color: white;

      .buy {
        width: 10.25rem;
        background: #009393;
        border-radius: 1.25rem;

        &.dis {
          background: #d4d4d4;
          color: #999999;
        }
      }
    }

    .buyTips {
      text-align: center;
      margin-top: 1rem;
      span {
        color: #f14d4d;
        cursor: pointer;
      }
    }

    .tips {
      text-align: center;
      font-size: 0.75rem;
      font-weight: 400;
      color: #999999;
      margin-top: 1rem;
    }
  }
}

@theme: #96A48D;@primary-color: #96A48D;@border-radius-base: 4px;